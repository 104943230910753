import React, {useState, useEffect} from "react"
import Layout from "../components/layout"
import Curso from "../components/curso"
import Tree from "../images/cursos/Tree.webp"
import Nietzche from "../images/cursos/Nietzche.webp"

const NietzcheComp = () => {
    const [hasMounted, setHasMounted] = useState(false) 

    useEffect(() => {
        setHasMounted(true)
    }, [])

    return hasMounted ? (
        <Layout>
            <Curso 
                title='Friedrich Nietzsche'
                phrase='Convertirnos en seres creadores.'
                time='8 Horas'
                class='6 Sesiones'
                students='3 - 6 Estudiantes'
                firstImg={Tree}
                secondImg={Nietzche}
                description='En este curso revisaremos los conceptos e ideas fundamentales de la obra de F. Nietzsche, enfocándonos siempre en las implicaciones personales y cotidianas que tienen esas ideas.'
                list={['El concepto del Übermensch o el Superhombre,', 'Las tres transformaciones del espíritu.', 'Dios ha muerto.', 'La voluntad de poder.']}
                duracion='6 sesiones de entre 80 y 90 min.'
                cupo='mínimo 3 personas, máximo 6 personas.'
                precio='$1,500 MXN.'
                top='10%'
                imgWidth='20%'
                right='5%'
                topMob='15%'
                rightMob='0'
                imgWidthMob='35%'
            />
        </Layout>
    ) : null
}

export default NietzcheComp